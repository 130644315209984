/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import BackgroundImgSrc from "../../images/Background.jpg";

const SEO = ({ description, title }) => (
  <Helmet>
    <meta lang="et" />
    <meta charSet="utf-8" />
    <title>ARMACOM OÜ – {title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={`ARMACOM OÜ – ${title}`} />
    <meta property="og:description" content={description} />
    <meta
      property="og:image"
      content={`https://www.armacom.ee${BackgroundImgSrc}`}
    />
    <meta name="twitter:title" content={`ARMACOM OÜ – ${title}`} />
    <meta name="twitter:description" content={description} />
    <meta
      name="twitter:image"
      content={`https://www.armacom.ee${BackgroundImgSrc}`}
    />
    <meta name="twitter:card" content="summary_large_image" />
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"
      rel="stylesheet"
    ></link>
  </Helmet>
);

SEO.defaultProps = {
  description: `ARMACOM OÜ on juba ligi 14 aastat tegutsenud metsandusettevõte. Ostame raieõigust ja metsakinnistuid.`
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default SEO;
